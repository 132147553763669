<template>
	<main>
		<div class="header">
			<div class="title-box">
				<div class="btn" :class="[collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold']" @click="toggleCollapse"/>
				<div class="title">{{title}}</div>
			</div>
			<div class="action-box">
				<div class="action">
					<el-button class="item" :loading="save_loading" @click="toSave">保存</el-button>
					<el-button class="item" :loading="preview_loading" @click="toPreview">预览</el-button>
					<el-button class="item" @click="toHistory">历史版本</el-button>
					<el-button type="primary" :loading="push_loading" class="item" @click="toPush">发布</el-button>
				</div>
				<div class="user" @click="loginOut">
					<div class="avatar">{{avatar}}</div>
					<div class="name">{{ admin_info.nickname }}</div>
				</div>
			</div>
		</div>
		<components-history ref="history" @reset="reset"/>
	</main>
</template>

<script>
import {site,auth,version} from "@/api/admin.js";
import {MessageBox} from "element-ui"
import {site_config} from "@/config/index.js";
import moment from "moment";
import axios from "axios";
import ComponentsHistory from "./header/History"

export default {
	components:{ComponentsHistory},
	props:{
		collapse:false,
	},
	data(){
		return {
			save_loading:false,
			preview_loading:false,
			push_loading:false,
		}
	},
	computed:{
		admin_info(){
			return this.$store.state.admin.admin_info
		},
		avatar(){
			return this.admin_info.username ? this.admin_info.username.split('')[0] : ''
		},
		title(){
			return `${site_config.title}CMS管理系统`
		}
	},
	methods:{
		toSave(){
			this.save_loading = true;
			version.push().then((res)=>{
				this.toUpload(res,'preview.json').then((url)=>{
					this.$message.success('保存成功');
				}).finally((res)=>{
					this.save_loading = false;
				})
			}).catch((res)=>{
				this.save_loading = false;
			})
		},
		toPreview(){
			this.preview_loading = true;
			version.push().then((res)=>{
				this.toUpload(res,'preview.json').then((url)=>{
					window.open('/?version=preview.json','_blank')
				}).finally((res)=>{
					this.preview_loading = false;
				})
			}).catch((res)=>{
				this.preview_loading = false;
			})
		},
		toHistory(){
			this.$refs['history'].init();
		},
		toPush(){
			this.$confirm('确定要发布吗？','提示').then(()=>{
				this.push_loading = true;
				version.push().then((res)=>{
					const filename = moment().format('YYYYMMDDHHmmss')+'.json';
					this.toUpload(res,filename).then((url)=>{
						this.toUpload({
							create_time:moment().format('YYYY-MM-DD HH:mm:ss'),
							url:url
						},'version.json').then((res)=>{
							this.$message.success('发布成功');
							version.create({
								url:url
							})
						}).finally((res)=>{
							this.push_loading = false;
						})
					}).catch((res)=>{
						this.push_loading = false;
					})
				})
			})
		},
		reset({content,filename}){
			this.toUpload(content,filename).then((url)=>{
				this.toUpload({
					create_time:moment().format('YYYY-MM-DD HH:mm:ss'),
					url:url
				},'version.json').then((res)=>{
					this.$message.success('发布成功');
					this.$refs['history'].close();
					version.create({
						url:url
					})
				})
			})
		},
		toUpload(content,filename){
			var data = JSON.stringify(content, undefined, 4);
			var file = new Blob([data], { type: "text/json" });
			var dir = site_config.json_dir

			return new Promise((resolve, reject)=>{
				site.oss({
					dir:dir+'/'
				}).then((res)=>{
					const config = res.config;
					const formData = new FormData();
					formData.append('key', config.dir + filename);
					formData.append('policy', config.policy);
					formData.append('OSSAccessKeyId', config.accessid);
					formData.append('success_action_status', 200);
					formData.append('signature', config.signature);
					formData.append('file', file);

					axios.post(config.host,formData).then((res)=>{
						const url = config.cdn + '/' + config.dir+filename;
						resolve(url);
					}).catch((error)=>{
						reject(error);
					})
				}).catch((res)=>{
					reject(error);
				})
			})
		},
		loginOut(){
			MessageBox.confirm('确定要退出登录吗？','提示',{
				type:"warning"
			}).then((res)=>{
				auth.logout().then((res)=>{
					this.$store.commit('admin/setToken','')
					this.$store.commit('admin/setTags',[])
					this.$router.push('/admin/auth/login')
				})
			})
		},
		toggleCollapse(){
			this.$emit('toggleCollapse')
		}
	}
}
</script>

<style lang="scss" scoped>
.header{height:56px;display: flex;align-items: center;justify-content: space-between;padding: 0 15px;
	.title-box{display: flex;align-items:center;
		.btn{width: 30px;height:30px;font-size: 20px;text-align: center;line-height: 30px;cursor: pointer;}
		.title{font-size: 20px;font-weight: bold;margin-left: 15px;color:$admin_main;}
	}
	.action-box{display: flex;align-items: center;
		.action{font-size: 14px;
			.item{cursor: pointer;}
		}
		.user{margin-left: 30px;display: flex;align-items: center;cursor: pointer;
			.avatar{width: 30px;height:30px;background: $admin_main;border-radius: 50%;font-size: 16px;color:#fff;text-align: center;line-height: 30px;text-transform: uppercase;}
			.name{margin-left: 10px;font-size: 16px;}
		}
	}
}
@media screen and (max-width: 1200px) {
	.header{
		.title-box{
			.title{display: none;}
		}
	}
}
</style>

